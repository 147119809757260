import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import ClipboardJS from 'clipboard';

import * as toastr from "toastr";

var clipboard = new ClipboardJS('.js-clipboard');

clipboard.on('success', function (e) {

    e.clearSelection();

    toastr.success('Emailadressen werden gekopieerd');

    $(e.trigger).blur();
});
