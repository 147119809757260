import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import { debounce } from './helpers';

import 'datatables.net-dt';

import qs from 'qs';

const $document = $(document);

const initialQueryObj = qs.parse(location.search);

$.extend(true, $.fn.DataTable.defaults, {
    responsive: true,
    processing: true,
    serverSide: true,
    searching: true,
    pageLength: 25,
    stateSave: true,
    lengthChange: true,
    displayLength: 25,
    lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'Alle']],
    dom: 'frt<"dataTables_footer"ilp>',
    language: {
        paginate: {
            first: 'Eerste',
            last: 'Laatste',
            next: 'Volgende',
            previous: 'Vorige'
        },
        info: '_START_ tot _END_ van totaal _TOTAL_ resultaten',
        infoEmpty: '0 resultaten gevonden',
        infoFiltered: '(gefilterd van totaal _MAX_ resultaten)',
        lengthMenu: 'Toon  _MENU_  rijen',
        search: '',
        processing: 'Vernieuwen',
        searchPlaceholder: 'Zoeken op...',
        zeroRecords: 'Er is geen data beschikbaar.',
        emptyTable: 'Er is geen data beschikbaar.'
    }
});

$('.js-datatable').each(function () {

    const $this = $(this);
    const $search = getClosestSibling(this, '.js-datatable-search');

    $this.dataTable($.extend({

    }, JSON.parse($this.attr('data-settings'))));

    if ($search) {

        setTimeout(function () {

            $search.val(initialQueryObj.search || '').trigger('keyup');
        }, 100);
    }
});

$('.js-datatable').on('draw.dt', function () {
    $document.trigger('datatable_draw');

    if ($document.ready()) {
        var volunteerTarget = document.getElementById('volunteers');
        if (volunteerTarget && window.location.hash === '#volunteers') {
            window.scroll({
                top: volunteerTarget.offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        }

        var userTarget = document.getElementById('users');
        if (userTarget && window.location.hash === '#users') {
            window.scroll({
                top: userTarget.offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        }

    }
    /*document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
        }
    };*/
});

$('.js-datatable-search').on('keyup', debounce(function () {

    const $dataTable = getClosestSibling(this, '.js-datatable');
    const $searchClear = getClosestSibling(this, '.js-datatable-search-clear');

    if (!$dataTable) return;

    $dataTable.DataTable().search(this.value).draw();

    const queryObj = qs.parse(location.search.substring(1));

    queryObj.search = this.value || undefined;

    const queryString = qs.stringify(queryObj);

    if (queryString.length) {

        history.replaceState(queryObj, '', `${location.pathname}?${queryString}`);
        $searchClear.show();
    } else {

        history.replaceState(queryObj, '', location.pathname);
        $searchClear.hide();
    }

}, 500));

$('.js-datatable-search-clear').on('click', function () {

    const $search = getClosestSibling(this, '.js-datatable-search');

    $search.val('').trigger('keyup');
});

$('.js-datatable-customizer input').on('change', function () {

    const $customizer = $(this).closest('.js-datatable-customizer');
    const $dataTable = getClosestSibling(this, '.js-datatable');

    if (!$dataTable) return;

    let columns = [];

    if ($.fn.DataTable.isDataTable($dataTable)) {
        $dataTable.DataTable().destroy();
    }

    $dataTable.find('thead tr').html('');
    $dataTable.find('tbody').html('');

    $customizer.find('input:checked').each(function () {

        var $this = $(this);

        $dataTable.find('thead tr').append('<th>' + $this.attr('data-label') + '</th>');

        columns.push(JSON.parse(this.value));
    });

    $dataTable.DataTable($.extend({}, JSON.parse($dataTable.attr('data-settings')), {
        columns: columns
    }));
}).first().trigger('change');

function getClosestSibling(el, selector) {

    let $parent = $(el).parent();
    let $dataTable = $parent.find(selector);

    while (!$dataTable.length) {

        $parent = $parent.parent();
        $dataTable = $parent.find(selector);

        if ($parent.is(document)) {
            return null;
        }
    }

    return $dataTable;
}
