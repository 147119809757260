import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import * as toastr from 'toastr';

import 'parsleyjs';

import { extend } from './helpers';
import { submitForm } from './form-helpers';

import '../parsley/nl';
const $document = $(document);
const $forms = $('form');

function errorsContainer(ParsleyField) {
  const $formItem = ParsleyField.$element.closest('.form__item');
  var $formGroup = $formItem.closest('.form__group');

  if ($formGroup.length) {
    return $formGroup;
  } else {
    return $formItem;
  }
}

extend(window.Parsley.options, {
  classHandler: errorsContainer,

  errorClass: 'has-error',

  errorsContainer: errorsContainer,

  errorsWrapper: '<ul class="form__errors"></ul>',

  excluded: 'input:not(:visible), input.novalidate'
});

window.Parsley.setLocale(document.documentElement.lang);

window.Parsley.on('field:error', function() {
  const $errorElement = window.Parsley.options.classHandler(this);
  $errorElement.closest('.form').addClass('has-errors');
  $errorElement.find('.form__errors-server').remove();
});

$forms
  .attr('autocomplete', 'off')
  .find('input, textarea, select')
  .attr('autocomplete', 'new-password');

$forms.on('click', 'button[type=submit]', function() {
  submitForm($(this.form));
});

window._beforeSubmit = function(e) {
  return $('.js-captcha')
    .parsley()
    .validate();
};

(function() {
  $('.js-upload-btn').on('click', function(e) {
    e.preventDefault();

    var $formItem = $(this).closest('.form__item');
    var $uploadFields = $formItem.find('.js-upload-input');

    $uploadFields.sort(function(a, b) {
      return (
        parseInt($(a).attr('data-id'), 10) > parseInt($(b).attr('data-id'), 10)
      );
    });

    var highestId = $uploadFields.last().attr('data-id');
    var newId = parseInt(highestId, 10) + 1;

    var $newUploadField = $(
      `<input type="file" name="${$uploadFields.attr(
        'name'
      )}" class="visuallyhidden js-upload-input" data-id="${newId}">`
    );

    $newUploadField.appendTo($formItem);
    $newUploadField.trigger('click');
  });

  $document.on('change', '.js-upload-input', function() {
    var $this = $(this);
    var $formItem = $this.closest('.form__item');
    var $uploadList = $formItem.find('.js-upload-list');
    var $uploadCount = $formItem.find('.js-upload-count');

    var id = $this.attr('data-id');
    var value = $this.val();

    var $uploadListItem = $uploadList.find(`li[data-id="${id}"]`);
    var uploadListItemExists = $uploadListItem.length;

    if (value) {
      var filename = $this
        .val()
        .split('\\')
        .pop();

      if (uploadListItemExists) {
        $uploadListItem.find('.js-upload-filename').html(filename);
      } else {
        var uploadListItemTemplate = $uploadList.attr('data-template');
        var uploadListItemHtml = uploadListItemTemplate
          .split('%filename%')
          .join(filename)
          .split('%id%')
          .join(id);
        var $uploadListItem = $(uploadListItemHtml);

        $uploadListItem.appendTo($uploadList);
      }
    } else {
      if (uploadListItemExists) {
        $uploadListItem.remove();
      }

      $this.remove();
    }

    $uploadCount.html($uploadList.children().length);
  });

  $document.on('click', '.js-upload-remove', function() {
    var $this = $(this);
    var $formItem = $this.closest('.form__item');
    var $uploadList = $formItem.find('.js-upload-list');
    var $uploadCount = $formItem.find('.js-upload-count');

    var id = $this.attr('data-id');

    var $uploadListItem = $this.closest('li');
    var $uploadField = $formItem.find(`input[data-id="${id}"]`);

    $uploadListItem.remove();
    $uploadField.remove();

    $uploadCount.html($uploadList.children().length);
  });
})();

$('.js-form-collection-add').each(function() {
  const $formCollectionAdd = $(this);
  const $formCollection = $formCollectionAdd.closest('.js-form-collection');
  const $formCollectionItemContainer = $formCollection
    .find('.js-form-collection-container')
    .first();

  const formCollectionItemTemplate = $formCollection
    .find('.js-form-collection-template')
    .first()[0].innerHTML;

  $formCollectionAdd.on('click', function(e) {
    e.preventDefault();

    let currentIndex = 0;

    $formCollectionItemContainer.children().each(function() {
      const collectionItemId = parseInt($(this).attr('data-id'), 10);

      currentIndex = Math.max(currentIndex, collectionItemId);
    });

    currentIndex++;

    $formCollectionItemContainer.append(
      $(formCollectionItemTemplate.split('%id%').join(currentIndex))
    );

    $(document).trigger('collectionadd');
  });

  $formCollectionItemContainer.on(
    'click',
    '.js-form-collection-item-remove',
    function() {
      $(this)
        .closest('.js-form-collection-item')
        .remove();
    }
  );
});

$('.js-form-image-input').on('change', function() {
  const $preview = $(this)
    .closest('.js-form-image')
    .find('.js-form-image-preview');
  const defaultImage = $preview.attr('data-default');

  if (this.files.length) {
    const reader = new FileReader();

    reader.readAsDataURL(this.files[0]);

    reader.onload = () => {
      $preview.html(`<img src="${reader.result}" alt="">`);
    };
  } else if (defaultImage) {
    $preview.html(`<img src="${defaultImage}" alt="">`);
  }
});

$('.js-form-toggle').on('change', function() {
  $(document.getElementsByClassName(this.dataset.class)).toggle();
});

(function groupEdit() {
  const $form = $('.js-form-group-edit');
  const $members = $form.find('select.js-form-group-members');
  const $modalTrigger = $('<a href="javascript:void(0)"/>');

  let isModalInitialized = false;
  let isModalConfirmed = false;

  $modalTrigger.appendTo($form);

  $form.on('submit', function(e) {
    if (!$form.parsley().isValid()) {
      return;
    }

    if (isModalConfirmed) {
      return;
    }

    if (!$members[0].selectize.getValue().length) {
      e.preventDefault();

      $form.data('is-submitted', false).removeClass('is-submitted');

      if (!isModalInitialized) {
        $modalTrigger.modaal({
          content_source: '#modal-group-no-members'
        });

        isModalInitialized = true;
      }

      $modalTrigger.trigger('click');

      return false;
    }
  });

  $('.js-form-group-edit-submit').on('click', function() {
    isModalConfirmed = true;

    $form.submit();
  });
})();

(function homeOptions() {
  const $scopes = $('.js-form-home-options');
  const $selectsVolunteerType = $scopes.find('select[name="volunteerType"]');
  const $inputsHomeOptions = $scopes.find('input[name="home_options[]"]');

  $selectsVolunteerType
    .on('change', function() {
      const $select = $(this);
      const $scope = $select.closest('.js-form-home-options');
      const $targets = $scope.find('[data-volunteer-type]');
      const $targetsWrapper = $scope.find('.js-form-home-options-toggle');

      const volunteerType = $select
        .find(`option[value="${$select.val()}"]`)
        .attr('data-type');

      $targetsWrapper.toggle(!!volunteerType && volunteerType !== 'none');

      if (volunteerType == 'telephone-chat') {
        $targets.show();
      } else {
        $targets
          .hide()
          .filter(`[data-volunteer-type^="${volunteerType}"]`)
          .show();
      }
    })
    .trigger('change');

  $inputsHomeOptions
    .on('change', function() {
      const $scope = $(this).closest('.js-form-home-options');
      const $selectVolunteerTypeOptions = $scope.find(
        'select[name="volunteerType"] option'
      );
      const $inputsChecked = $scope.find(
        'input[name="home_options[]"]:checked'
      );

      $selectVolunteerTypeOptions.prop('disabled', false);

      $inputsChecked.each(function() {
        const volunteerType = $(this)
          .closest('[data-volunteer-type]')
          .attr('data-volunteer-type');
        $selectVolunteerTypeOptions
          .filter(function() {
            const optionType = $(this).attr('data-type');
            return optionType && optionType.indexOf(volunteerType) < 0;
          })
          .prop('disabled', true);
      });
    })
    .trigger('change');
})();

function toggleValue(context = document) {
  [].map
    .call($('[data-toggle-name]'), el => el.dataset.toggleName)
    .filter((name, index, self) => self.indexOf(name) === index)
    .forEach(name => {
      $(context)
        .find(`[name="${name}"]`)
        .on('change', function() {
          const $form = $(this.form);
          const $targets = $form.find(`[data-toggle-name="${this.name}"]`);
          $targets.hide();
          $form.find(`[name="${this.name}"]:checked`).each(() => {
            $targets.each((index, el) => {
              if (el.dataset.toggleValue.split(',').includes(this.value)) {
                $(el).show();
              }
            });
          });
        })
        .filter(':checked')
        .trigger('change');
    });
}

toggleValue();

//  Submit forms on change event, without submit button
function initAutosubmit() {
  $('.js-form-autosubmit')
    .not('.is-initialized')
    .find('input, select, textarea')
    .on('change', function() {
      $(this.form)
        .addClass('is-initialized')
        .submit();
    });
}

initAutosubmit();

function initToggleFetch() {
  $('.js-form-toggle-fetch')
    .not('.is-initialized')
    .addClass('is-initialized')
    .find('input')
    .on('change', function(e) {
      e.preventDefault();
      const $input = this;
      $.post(
        $input.form.action,
        $($input.form).serializeArray(),
        function(data) {
          $input.checked = data[$input.name];
          toastr.remove();
          toastr[data.success ? 'success' : 'error'](data.message);
        },
      );
    });
}

initToggleFetch();

$document.on('datatable_draw', initAutosubmit);
$document.on('datatable_draw', initToggleFetch);

window.app = window.app || {};
window.app.form = { toggleValue };
