import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import { loadScript } from './helpers';

(function geolocation() {

    const $geoInputs = $('.js-geolocation');
    const GOOGLE_API_KEY = 'AIzaSyBnpeyM3eBpaoRQ-FfqLpvub2SAwf8vT3U';

    /*if (!$geoInputs.length) return;*/

    if (typeof google === 'undefined') {
        return loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&language=nl&output=json`, geolocation);
    }

    const geocoder = new google.maps.Geocoder();
    const sessionToken = new google.maps.places.AutocompleteSessionToken();
    const defaultOptions = {
        strictBounds: true,
        sessionToken,
        componentRestrictions: {
            country: ['be', 'nl', 'fr']
        }
    };

    $geoInputs.each(function () {

        const $input = $(this);
        const $formItem = $input.closest('.form__item');

        const hiddenInputName = `${$input.attr('name')}-geolocation`;
        const plainText = `${$input.attr('name')}-plain`;

        let $hiddenInput = $formItem.find(`[name="${hiddenInputName}"]`);
        let $plainText= $formItem.find(`[name="${plainText}"]`);

        if (!$hiddenInput.length) {
            $hiddenInput = $(`<input type="hidden" name="${hiddenInputName}">`);

            $hiddenInput.appendTo($formItem);
        }

        if (!$plainText.length) {
            $plainText = $(`<input type="hidden" name="${plainText}">`);

            $plainText.appendTo($formItem);
        }

        $input.on('change keydown', function() {
            $plainText[0].value = $input.val();
        });

        const inputOptions = $input.attr('data-options') || {};

        const autocomplete = new google.maps.places.Autocomplete(this, { ...defaultOptions, ...inputOptions });

        //  From the address, get the lat, lng, googleId, ... to send to the server in a hidden field
        autocomplete.addListener('place_changed', function () {
            const place = autocomplete.getPlace();

            $hiddenInput[0].value = JSON.stringify(place);
        });

        // Prevent to submit the form when you hit enter inside the location input
        google.maps.event.addDomListener(this, 'keydown', e => {

            if (e.keyCode == 13) {
                e.preventDefault();
                return false;
            }
        });
    });
})();
