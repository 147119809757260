import $ from 'jquery';
window.$ = $;
window.jQuery = $;

$('.js-slot-nightshift').on('change', function() {
    const $this = $(this);

    $this
        .closest('.js-slot')
        .toggleClass('roster-slot--nightshift', $this.prop('checked'));
});

$('.js-roster-day-duplicate').on('click', function() {
    const $this = $(this);
    const $duplicatedDay = $this.closest('.js-roster-day');
    const $slots = $duplicatedDay.find('.js-form-collection-item');

    const dayData = $slots.map(function() {
        const $slot = $(this);

        return {
            id: $slot.attr('data-id'),
            hour: $slot.find('[name$="[hour]"]').val(),
            duration: $slot.find('[name$="[duration]"]').val(),
            devices: $slot.find('[name$="[devices]"]').val(),
            is_night: $slot.find('[name$="[is_night]"]').val()
        };
    });

    $('.js-roster-day')
        .not($duplicatedDay)
        .each(function() {
            const $day = $(this);
            const $collectionContainer = $day.find(
                '.js-form-collection-container'
            );
            const $templateContainer = $day.find(
                '.js-form-collection-template'
            );

            if (!$templateContainer.length) return;

            const template = $templateContainer[0].innerHTML;

            $collectionContainer.empty();

            $.each(dayData, function() {
                const $slot = $(template.split('%id%').join(this.id));

                $slot.find('[name$="[hour]"]').val(this.hour);
                $slot.find('[name$="[duration]"]').val(this.duration);
                $slot.find('[name$="[devices]"]').val(this.devices);
                $slot.find('[name$="[is_night]"]').val(this.is_night);

                $slot.appendTo($collectionContainer);
            });
        });
});

let $modalTrigger = $('.modal-roster-validation').modaal({
    type: 'inline',
    content_source: '#modal-roster-validate',
    start_open: false,
    is_locked: true,
    hide_close: true,
    overlay_close: false,
    after_callback_delay: 500
});

$('.js-validate-slots').on('click', function() {
    const $this = $(this);
    let url = $this.attr('data-url');

    $.ajax({
        type: 'get',
        url: url,
        beforeSend: function() {
            $this.toggleClass('is-loading');
            $modalTrigger.modaal('open');
        },
        success: function(result) {
            $this.toggleClass('is-loading');
            showRosterErrors(result);
        },

        complete() {
            $modalTrigger.modaal('close');
            $('body, html').scrollTop(0);
        }
    });
});

function showRosterErrors(result) {
    const $container = $('.js-slot-error-container');
    const $button = $('.js-btn-new-step');

    const resultArray = JSON.parse(result);

    if (resultArray['error']) {
        $container.html(`
            <div class="notice notice--danger">
                <p><strong>De locatiecheck leverde volgende problemen op. Tijdens volgende slots is geen vrije ruimte beschikbaar:</strong></p>
                <ul>${resultArray['error']
                    .map(error => `<li>${error}</li>`)
                    .join('')}</ul>
            </div>
        `);

        resultArray['duplicates'].forEach(slotId => {
            window.vueSlots[slotId].setIsOverlapping(true);
        });

        $button.addClass('btn--disabled');
    } else if (resultArray['success']) {
        $container.html(
            `<div class="notice notice--success">${
                resultArray['success']
            }</div>`
        );
        $container.addClass('spacer');

        // resultArray['duplicates'].forEach(slotId => {
        //     window.vueSlots[slotId].
        // });

        $button.removeClass('btn--disabled');
    }
}
