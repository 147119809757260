import * as toastr from "toastr";

toastr.options.preventDuplicates = true;
toastr.options.positionClass = "toast-bottom-center";
toastr.options.closeButton = true;
toastr.options.closeHtml = '<button><span class="icon icon--clear"></span></button>';

// set to 0 to prevent auto hiding, makes it easier to develop
// toastr.options.timeOut = 0;
// toastr.options.extendedTimeOut = 0;
