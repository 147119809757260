import $ from 'jquery';
window.$ = $;
window.jQuery = $;

$('.js-toggle-highlighted').on('click', function (e) {

    const $this = $(this);
    const newLabel = $this.attr('data-label');

    $this.attr('data-label', $this.text());
    $this.text(newLabel);

    $('.js-is-not-highlighted').toggle();
});
