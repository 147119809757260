import $ from 'jquery';
window.$ = $;
window.jQuery = $;

initDropdown();

function initDropdown() {

    $(document).on('click', '.js-dropdown-trigger', event => {

        event.preventDefault();

        const $dropdown = $(event.target).closest('.js-dropdown');

        $dropdown.toggleClass('is-open');
    });

    $(document).on('click', event => {

        if (!$(event.target).closest('.js-dropdown').length && $('.js-dropdown').hasClass('is-open')) {

            $('.js-dropdown').removeClass('is-open');
        }
    });
}
