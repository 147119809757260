window.app = window.app || {};
window.app.variables = window.app.variables || {};

import './components/common' ;
import './components/form' ;
import './components/selectize';
import './components/geolocation';
import './components/flyout';
import './components/datepicker';
import './components/dropdown';
import './components/collapsible';
import './components/dataTables';
import './components/modaal';
import './components/quill';
import './components/cleave';
import './components/toggle-highlighted';
import './components/roster';
import './components/tooltips';
import './components/clipboard';
import './components/toastr';
