import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import Cleave from 'cleave.js';

initCleave();

$(document).on('collectionadd', initCleave);

function initCleave() {

    $('.js-cleave-time').each(function () {

        const $this = $(this);

        if ($this.data('cleave-initialized')) return;

        new Cleave(this, {
            time: true,
            timePattern: ['h', 'm'],
        });

        $this.data('cleave-initialized', true);
    });
}
