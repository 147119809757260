import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import { debounce, isBreakpointActive } from './helpers';

const $document = $(document);
const $window = $(window);
const $html = $('html');
const $body = $('body');

windowResize();

$window.on('resize', debounce(windowResize, 250, false));

$window.on('load', jsDone);

setTimeout(jsDone, 4000);

function windowResize() {

    const isMobile = isBreakpointActive('flyout');
    const windowWidth = $window.width();
    const windowHeight = $window.height();

    if (isMobile) {
        $body.removeClass('flyout-active');
    } else {

        const isFlyoutActive = localStorage.getItem('isFlyoutActive') === null || localStorage.getItem('isFlyoutActive') === 'true';

        $body.toggleClass('flyout-active', isFlyoutActive);
    }

    $.extend(window.app.variables, {
        isMobile,
        windowWidth,
        windowHeight
    });
}

function jsDone() {
    $html.addClass('js-done');
    $body.addClass('flyout-enabled should-animate-flyout');
}
