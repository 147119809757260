import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import('moment').then(({ default : moment }) => {
    window.moment = moment;
import('pikaday').then(({ default: Pikaday }) => {
        window.Pikaday = Pikaday;
        initializeDatePicker();
    });
});

const MONTHS = [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
];

const WEEKDAYS = [
    'Zondag',
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
];

const SHORT_WEEKDAYS = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'];

function initializeDatePicker() {
    $('.js-datepicker').each(function () {
        new Pikaday({
            moment: window.moment,
            field: this,
            firstDay: 1,
            format: 'DD/MM/YYYY',
            toString: function (date, format) {
                return window.moment(date).format(format);
            },
            yearRange: 80,
            i18n: {
                previousMonth: 'Vorige maand',
                nextMonth: 'Volgende maand',
                months: MONTHS,
                weekdays: WEEKDAYS,
                weekdaysShort: SHORT_WEEKDAYS,
            },
        });
    });
}
