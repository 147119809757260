import $ from 'jquery';

window.$ = $;
window.jQuery = $;

import selectize from 'selectize';

const selectizeDefaults = {
    plugins: ['remove_button'],
    closeAfterSelect: true,
    copyClassesToDropdown: false
};

$.selectize = selectize;

$(document).on('click', '.js-selectize', function (e) {
    if ($(e.target).is('.js-selectize')) {
        $(this)
            .find('input')
            .focus();
    }
});

initSelectize();

export function initSelectize() {
    $('select.js-selectize').each(function () {
        //  already initialized
        if ($(this).is('.selectized')) return;

        const config = JSON.parse(this.dataset.config || '{}');

        const $select = $(this).selectize({
            ...selectizeDefaults,
            ...config
        });

        $(this)
            .parent()
            .find('.js-selectize-clear')
            .on('click', function () {
                $select[0].selectize.clear();
            });
    });

    $('select.js-selectize-wire').each(function () {
        // Skip already initialized select elements
        if ($(this).is('.selectized')) return;

        const $select = $(this).selectize({
            plugins: ['remove_button'],
            closeAfterSelect: true,
            copyClassesToDropdown: false,
            onChange: (value) => {
                Livewire.dispatch('updateSearch', { detail: { value }}); // Emit a Livewire event
            }
        });


        $(this)
            .parent()
            .find('.js-selectize-clear')
            .on('click', function () {
                $select[0].selectize.clear();
            });
    });
}
