import $ from 'jquery';
window.$ = $;
window.jQuery = $;

function toggleCollapsible($collapsible, isExpanded) {

    $collapsible.toggleClass('is-collapsed', !isExpanded);
    $collapsible.find('.js-collapsible-trigger').attr('aria-expanded', isExpanded);
    $collapsible.find('.collapsible__label').html(function () {
        return isExpanded ? $(this).attr('data-label-expanded') : $(this).attr('data-label-collapsed');
    });
}

$('.js-collapsible').each(function () {

    const $this = $(this);

    toggleCollapsible($this, !$this.hasClass('is-collapsed'));
});

$('.js-collapsible-trigger').on('click', function (e) {

    e.preventDefault();

    const $this = $(this);
    const $collapsibleParent = $(this).closest('.js-collapsible');
    const $collapsibleLabel = $collapsibleParent.find('.collapsible__label');

    const groupName = $collapsibleParent.attr('data-group');
    const willExpand = $collapsibleParent.hasClass('is-collapsed');

    if (groupName) {
        const $collapsibleSiblings = $(`.js-collapsible[data-group="${groupName}"]`).not($collapsibleParent).addClass('is-collapsed');

        toggleCollapsible($collapsibleSiblings, false);
    }

    toggleCollapsible($collapsibleParent, willExpand);
});
