import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import { initQuill } from './quill.js';

import { resetForm } from './form-helpers.js';

import 'modaal';

const $document = $(document);

const modaalOptions = {
  animation_speed: 0,

  after_callback_delay: 50,

  after_open(modalWrapper) {
    $(modalWrapper)
      .find('.js-quill')
      .each(function() {
        initQuill(this);
      });

    app.form.toggleValue(modalWrapper);
  }
};

window.app = window.app || {};
window.app.config = window.app.config || {};

window.app.config.modaalOptions = modaalOptions;

initModals();

$document.on('datatable_draw', function() {
  initModals();
});

$document.on('livewire_update', function() {
    initModals();
});

function initModals(element = document) {
  $(element)
    .find('.js-modal:not(.is-initialized)')
    .modaal(modaalOptions)
    .addClass('is-initialized');

  $(element)
    .find('.js-modal-new:not(.is-initialized)')
    .modaal({
      ...modaalOptions,

      //  Clear the form before closing
      before_open(modalWrapper) {
        resetForm($(modalWrapper).find('form'));
      }
    })
    .addClass('is-initialized');

  $(element)
    .find('.js-modal-edit:not(.is-initialized)')
    .modaal({
      ...modaalOptions,

      is_locked: true,

      hide_close: true
    })
    .addClass('is-initialized');

  $(element).on('click', '.js-modal-close', function(e) {
    e.preventDefault();

    const scope = $(this)
      .closest('.modaal-wrapper')
      .attr('id');
    const $modalTrigger = $(`[data-modaal-scope="${scope}"]`);

    if ($modalTrigger.is('.js-modal-edit')) {
      window.location.reload(true);
    } else {
      $modalTrigger.modaal('close');
      $modalTrigger.trigger('blur');
    }
  });
}

export { initModals };
